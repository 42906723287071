.tooltip {
  z-index: 10000;
  display: block !important;
  pointer-events: none;

  .tooltip-inner {
    max-width: 14rem;
    padding: 0.5em 1em;
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    background: var(--color-primary);
    border-radius: 0.25em;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    margin: 5px;
    border-color: var(--color-black);
    border-style: solid;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      border-width: 5px 5px 0 5px;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      border-width: 0 5px 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      left: -5px;
      margin-right: 0;
      margin-left: 0;
      border-width: 5px 5px 5px 0;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      right: -5px;
      margin-right: 0;
      margin-left: 0;
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.tooltip.popover {
  pointer-events: all;

  .tooltip-inner {
    font-size: 1rem !important;
  }
}
