@import 'colors';
@import 'range-slider';

input,
select,
option,
textarea {
  width: 100%;
  padding: 0.75rem 0.75rem 0.6rem 0.75rem;
  color: var(--color-text);
  font-weight: 500;
  font-size: 1rem;
  background: rgba($color-grey-100, 0.5);
  border: 0;
  border-radius: 3px;

  &:disabled {
    color: $color-grey-500;
    background: $color-grey-100;
    cursor: not-allowed;
  }
}

.v-select {
  width: 100%;
  .vs__selected-options {
    padding: 0.25rem !important;
  }

  .vs__dropdown-toggle {
    min-height: 3rem !important;
    padding-right: 0.5rem;
    background: rgba($color-grey-100, 0.5);
    border: 0;
    border-radius: 3px;
  }

  .vs__selected {
    margin-right: 0.25rem;
    padding: 0.25rem 0.75rem !important;
    background: white;
    border-radius: 0 !important;
  }
}

.form-group {
  display: block;
  margin: 1rem 0;
}

.form-label {
  display: block;
  margin-bottom: 0.25rem;
}

.form-field {
  display: block;
  padding: 1rem 0;

  &--error {
    margin-bottom: 0;
    color: $color-red;
  }

  &--small {
    max-width: 400px;
  }
}

.form-row {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  margin: 0.5rem 0 1rem;

  & > .form-group {
    margin: 0;
  }
  .form-field + .form-field {
    margin-left: 1rem;
  }
}

.form-help {
  display: block;
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
}

.form__checkbox {
  position: relative;
}

.form-group--checkbox {
  display: flex;
  margin: 1.5rem 0;

  .form__checkbox {
    z-index: 2;
    width: auto;
  }

  .form-label {
    display: block;
    flex-grow: 1;
  }
}

.preview-image {
  max-width: 200px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 5rem;
  transform: translateY(0.15rem);

  &__input {
    position: absolute;
    left: -999999px;
    width: 0;
    height: 0;
    background: none;

    &:focus ~ .toggle__switch {
      background: $color-link !important;
      outline: 4px solid var(--color-primary);
    }

    &:checked ~ .toggle__switch {
      background: var(--color-primary);

      &::before {
        left: 1.25rem;
        content: '\f00c';
      }

      &::after {
        content: 'På';
      }
    }
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 2rem;
    background: $color-grey-300;
    border-radius: 1rem;
    box-shadow: inset 0 1px 4px rgba($color-grey-800, 0.3);
    transition: all 0.3s ease-in-out;

    &::before {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      font-weight: 900;

      // color: white;
      font-family: 'Font Awesome 5 Free';
      text-align: center;
      background: white;
      border-radius: 50%;
      // box-shadow: 0 2px 4px rgba($color-grey-800, 0.7);
      transition: all 0.3s ease-in-out;
      content: '';
    }

    &::after {
      position: absolute;
      top: 0.5rem;
      right: -1.5rem;
      font-weight: 500;
      content: 'Av';
    }
  }

  &__wrapper {
    display: flex;
    align-content: baseline;
  }

  &__label {
    display: inline-block;
    margin-right: 1rem;
  }
}

.toggle__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
