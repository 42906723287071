@import 'colors';

.create-container {
  padding: 1rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);

  @media screen and (min-width: bp(s)) {
    width: span(8);
    padding: 1.5rem;
  }

  @media screen and (min-width: bp(m)) {
    width: span(5, 0, span(9));
    padding: 1.5rem;
  }

  @media screen and (min-width: bp(l)) {
    width: span(4, 0, span(10));
    padding: 1.5rem;
  }
}

.search {
  padding: 0;
}

.form__field {
  width: 100%;
  padding: 1rem;
  font-weight: 500;
  font-size: 1rem;
  background: $color-grey-50;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
