
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        


































































@import '@/styles/_colors';

.app {
  min-height: 100vh;
  background: $color-bg;
}

.container {
  @include container();
  display: flex;
  flex-wrap: wrap;
}

.sidebarContainer {
  display: none;

  @media screen and (min-width: bp(m)) {
    display: block;
    width: span(3);
  }

  @media screen and (min-width: bp(l)) {
    width: span(2);
  }
}

.main-view {
  width: span(12);

  @media screen and (min-width: bp(m)) {
    width: span(9);
    margin-left: span(0, 1);
  }
  @media screen and (min-width: bp(l)) {
    width: span(10);
  }
}

.router-view {
  min-height: calc(100vh - 20rem);
}
