$g-max-column-width: 7.5em !default; // 72px
$g-max-gutter-width: 1em !default; // 32px
$g-column-count: 12 !default;
$g-column-color: red !default; // fill color will be used at 10% opacity

// prettier-ignore
$g-user-breakpoints:
  'base' 0em 0.5em,
  'xs' 25em 1em,
  's' 40em 1em,
  'm' 60em 1em,
  'l' 80em 1em,
  'xl' 100em 1.5em,
  'xxl' 128em 2em
  !default;
