
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        
@import 'reset';
@import 'colors';
@import 'fonts';
@import 'typography';
@import 'buttons';
@import 'tabs';
@import 'forms';
@import 'widgets';
@import 'modal';
@import 'widgets';
@import 'tooltip';
@import 'admin';
@import 'alerts';

@import '~@braid/griddle/scss/griddle-overlay.scss';

html,
body {
  margin: 0;
  font-size: 14px;
}

button,
input,
textarea,
body {
  color: var(--color-text);

  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: scroll;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* When mouse is detected, ALL focused elements have outline removed. */

/* You could apply this selector only to buttons, if you wanted. */
body.using-mouse :focus {
  outline: none;
}

*:focus {
  outline: 2px solid $color-link;
}

.toast {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.table,
.md table {
  width: 100%;
  margin: 2rem 0 1rem;
  border-bottom: 1px solid var(--color-border);

  th {
    font-weight: 500;
  }

  thead {
    th {
      border-top: none;
    }
  }

  th,
  td {
    height: 3rem;
    padding: 0 0.5rem;
    text-align: left;
    vertical-align: middle;
    border-top: 1px solid var(--color-border);
  }
}

hr {
  height: 1px;
  margin: 1rem 0;
  background: $color-grey-100;
  border: none;
}

.main {
  position: relative;
  width: span(12);
  padding: 1.5rem 0;

  @media screen and (min-width: bp(m)) {
    width: span(9, 0, span(9));
  }

  @media screen and (min-width: bp(l)) {
    width: span(7, 0, span(10));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(7, 0, span(10));
  }
}

.aside {
  position: relative;
  width: span(12);
  margin-top: 0.5rem;

  @media screen and (min-width: bp(m)) {
    width: span(9, 0, span(9));
  }

  @media screen and (min-width: bp(l)) {
    width: span(3, 0, span(10));
    margin-left: span(0, 1, span(10));
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

:root {
  --color-text: var(--color-digital-black);
  --color-text-secondary: var(--color-digital-black);
  --color-primary: var(--color-pear);
  --color-primary-dark: var(--color-pear-dark);
  --color-secondary: var(--color-flamingo);
  --color-tertiary: var(--color-flamingo-dark);

  --color-mint: #DBEEDE;
  --color-digital-black: #333333;
  --color-pear: #B7DEBD;
  --color-pear-dark: #7b957f;
  --color-flamingo: #aa887a;
  --color-flamingo-dark: #FAC0B1;

  --color-danger: #ff8174;
  --color-success: #43f8b6;
  --color-warning: #f8c66b;
  --color-black: #2e2e2e;
  --color-white: #f9f9fa;
  --color-gray: #989898;
  --color-light-gray: #f6f6f6;
  --color-blue: #6ee9ff;
  --color-blue-light: #b3f5ff;
  --color-blue-light-2: #b2d2d8;
  --color-purple: #292858;
  --color-purple-dark: #1f1e43;
  --color-yellow: #f8c66b;
  --color-yellow-dark: #ba944fff;
  --color-red: #ff8174;
  --color-red-dark: #5b0800;
  --color-red-light: #ff988e;
  --color-beige: #f8f0dd;
  --color-beige-dark: #d0bfae;

  --color-green: #43f8b6;
  --color-green-dark: #034b45;
  --color-green-dark-2: #023834;
  --color-green-light: #c7f6c9;

  --color-bg: #f9f9fa;
  --color-border: #DBEEDE;

  --color-grey-50: #f7f7f7;
  --color-grey-100: #e1e1e6;
  --color-grey-200: #c6c6cc;
  --color-grey-300: #adadb3;
  --color-grey-400: #939399;
  --color-grey-500: #797980;
  --color-grey-600: #5e5e66;
  --color-grey-700: #43434d;
  --color-grey-800: #242433;
  --color-grey-900: #0e0e1a;

  --color-link: #0075eb;
}

[data-theme='green'] {
  --color-text: var(--color-green-dark);
  --color-text-secondary: var(--color-white);
  --color-primary: var(--color-green-dark);
  --color-primary-dark: var(--color-green-dark-2);
  --color-secondary: var(--color-green-light);
  --color-tertiary: var(--color-green-dark);
  --color-border: #dae3e5;
}

[data-theme='blue'] {
  --color-text: var(--color-purple);
  --color-text-secondary: var(--color-white);
  --color-primary: var(--color-purple);
  --color-primary-dark: var(--color-purple-dark);
  --color-secondary: var(--color-blue);
  --color-tertiary: var(--color-purple);
  --color-border: #dae3e5;
}

[data-theme="yellow"] {
  --color-text: var(--color-purple);
  --color-text-secondary: var(--color-purple);
  --color-primary: var(--color-yellow);
  --color-primary-dark: var(--color-yellow-dark);
  --color-secondary: var(--color-beige);
  --color-tertiary: var(--color-beige-dark);
  --color-border: #dae3e5;
}
