@import 'colors';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: rgba(black, 0.5);
}

.modal {
  z-index: 100;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 550px;
  max-height: 90%;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0.25rem 0.45rem rgba(black, 0.5);

  &__header {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 0.5rem 2rem;
    font-size: 1rem;

    @media screen and (min-width: 1600px) {
      padding: 2rem;
      font-size: 1.5rem;
    }

    .btn {
      margin: 0;
      padding: 0 1.15rem;
    }

    .fa {
      margin: 0;
      font-size: inherit;
    }

    .title-2 {
      margin-right: auto;
      font-size: inherit;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 1rem;
    background-color: $color-grey-50;
  }

  &__footer {
    display: flex;
    padding: 1rem 2rem 1rem;
    font-size: 12px;
    background-color: $color-grey-50;
    border-top: 1px solid var(--color-border);
  }
}
