@font-face {
  font-weight: 400;
  font-family: 'OsloSans';
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/OsloSans-Regular.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-weight: 300;
  font-family: 'OsloSans';
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/OsloSans-Light.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-weight: 500;
  font-family: 'OsloSans';
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/OsloSans-Medium.woff') format('woff');
  font-display: auto;
}
