@import 'typography';

.details {
  display: flex;
  flex-direction: column;
}

.details__item {
  margin: 0.5rem 0;
  line-height: 1.2rem;
}

.details__item-body {
  display: flex;
}

.details__item-icon {
  flex-shrink: 0;
  align-self: flex-start;
  width: 1.5rem;
  margin-top: 0.1rem;
  margin-left: 0.15rem;
}

.main-widgets {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  @media screen and (max-width: bp(s)) {
    flex-direction: column;
  }
}

.main-widgets__title {
  margin-bottom: 0.5rem;
  color: $color-grey-400;
  font-weight: 500;
  font-size: $font-size-0;
  text-transform: uppercase;
}

.main-widgets__current {
  align-self: flex-start;
  width: span(3);
  margin-bottom: 0.5rem;

  padding: 1rem;
  background: white;
  box-shadow: 0 2px 3px rgba(black, 0.1);

  @media screen and (max-width: bp(s)) {
    width: span(9);
  }

  @media screen and (min-width: bp(m)) {
    width: span(2, 0, span(9));
  }

  @media screen and (min-width: bp(l)) {
    width: span(2, 0, span(7));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(2, 0, span(6));
  }
}

.main-widgets__current--value {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 50px;
}

.main-widgets__current--unit {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-transform: capitalize;
}

.main-widgets__current--children {
  display: flex;
  flex-direction: column;
}

.main-widgets__graph {
  width: span(9);

  margin-left: span(0, 1);
  padding: 1rem;
  background: white;
  box-shadow: 0 2px 3px rgba(black, 0.1);

  @media screen and (max-width: bp(s)) {
    margin-left: span(0);
  }

  @media screen and (min-width: bp(m)) {
    width: span(7, 0, span(9));
    margin-left: span(0, 1, span(9));
  }

  @media screen and (min-width: bp(l)) {
    width: span(5, 0, span(7));
    margin-left: span(0, 1, span(7));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(4, 0, span(6));
    margin-left: span(0, 1, span(6));
  }
}


.widgets {
  display: grid;
  grid-gap: span(0, 1, span(12));
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  width: 100%;

  @media screen and (min-width: bp(m)) {
    grid-gap: span(0, 1, span(9));
  }

  @media screen and (min-width: bp(l)) {
    grid-gap: span(0, 1, span(10));
    grid-template-columns: 1fr;
  }
}

.widgets__links {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 0;

  & > .btn {
    width: 100%;
  }
}
