@import 'colors';

.button-col {
  display: flex;
  flex-direction: column;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem -0.25rem -0.25rem;

  > .btn {
    margin: 0.25rem;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  color: var(--color-text);
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
  background: $color-grey-100;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.btn--danger {
  color: var(--color-red-dark);
  background: var(--color-red-light);
}

.btn--pri {
  color: var(--color-text-secondary);
  background: var(--color-primary);
}

.btn--sec {
  color: var(--color-text);
  background: var(--color-secondary);
}

.btn--icon {
  justify-content: flex-start;
  padding-right: 1rem;

  & > .icon {
    margin-right: 0.35rem;
  }
}

.btn--ter {
  background: none;
  &:hover {
    background: rgba($color-grey-500, 0.1);
  }

  &.btn--negative {
    color: white;

    &:hover {
      background: rgba(white, 0.1);
    }
  }
}

.btn--ghost {
  background: none;
  border: 1px solid currentColor;

  &.btn--negative {
    color: var(--color-white);
    border-color: var(--color-white);
  }
}

.btn--fw {
  justify-content: center;
  width: 100%;
}

.btn:disabled {
  color: var(--color-text-secondary);
  cursor: not-allowed;
  opacity: 0.6;
  filter: saturate(0.5);
}
